<template>
    <div class="page">
        <div class="container">
            <div class="row profile">
                <div class="col-sm-3">
                    <div class="profile__sidebar">
                        <div class="profile__name">{{ user.name }}</div>
                        <div class="profile__field">
                            <div class="profile__icon"></div>
                            <div class="profile__value">{{ user.email }}</div>
                        </div>
                        <div class="profile__field">
                            <div class="profile__icon"></div>
                            <div class="profile__value">{{ user.phone }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-9">
                    <div class="row order">
                        <div class="col-sm-7">
                            <div class="profile__content">
                                <div class="page__back">
                                    <router-link :to="{ name: 'ProfileOrders' }">
                                        <svg width="20" height="20">
                                            <use xlink:href="@/assets/images/icons.svg?v=72#icon-chevron-left"></use>
                                        </svg>
                                        Моите поръчки
                                    </router-link>
                                </div>

                                <div v-if="order != null">
                                    <div class="order__id">Поръчка №{{ order.id }}</div>
                                    <div class="order__service">Доставка <template v-if="!order.slot.text.includes('Днес') && !order.slot.text.includes('Утре')">на </template>{{ order.slot.text.toLowerCase() }}</div>
                                    <div class="order__date">Създадена е на {{ order.created_at | moment("DD MMM YYYY HH:mm") }}</div>
                                    <div class="order__address">
                                        Доставка за адрес: {{ order.address.street }}<template v-if="order.address.house != null && order.address.house.length > 0">, {{ order.address.house }}</template>
                                        <template v-if="order.address.entrance != null && order.address.entrance.length > 0">, вход {{ order.address.entrance }}</template>
                                        <template v-if="order.address.floor != null && order.address.floor.length > 0">, етаж {{ order.address.floor }}</template>
                                        <template v-if="order.address.apartment != null && order.address.apartment.length > 0">, ап. {{ order.address.apartment }}</template>
                                        <template v-if="order.address.comment != null && order.address.comment.length > 0">,<br>{{ order.address.comment }}</template>
                                    </div>
                                </div>
                            </div>

                            <div v-if="order != null" class="order__content">
                                <div class="order__summary">{{ order.products.length }} продукт<template v-if="order.products.length > 1">а</template> на стойност <span>{{ order.price.without_delivery }} лв.</span></div>
                                <div class="order__products">
                                    <div v-for="product in order.products" class="order-product">
                                        <div class="order-product__content">
                                            <div class="order-product__photo">
                                                <img :src="product.photo.url">
                                            </div>
                                            <div class="order-product__title">
                                                {{ product.title }} <span class="order-product__weight">{{ product.weight.text }}</span>
                                            </div>
                                        </div>
                                        <div class="order-product__info">
                                            <div v-if="product.price_sum.normal !== product.price_sum.special" class="order-product__price--old">{{ product.price_sum.normal }} лв.</div>
                                            <div class="clearfix"></div>
                                            <div class="order-product__price">
                                                <template v-if="product.price_sum.normal !== product.price_sum.special">{{ product.price_sum.special }} лв.</template>
                                                <template v-else>{{ product.price_sum.normal }} лв.</template>
                                            </div>
                                            <div class="order-product__quantity">{{ product.quantity }} бр.</div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <div v-if="order != null" class="order__content">
                                <div class="order__field">
                                    <div class="order__field-name">Доставка</div>
                                    <div class="order__field-value">
                                        <span v-if="order.delivery.price.normal !== order.delivery.price.special" class="order__field-value--discount">{{ order.delivery.price.normal }} лв.</span> {{ order.delivery.price.special }} лв.
                                    </div>
                                </div>
                                <div v-if="order.price.discount > 0" class="order__field">
                                    <div class="order__field-name">Отстъпки</div>
                                    <div class="order__field-value order__field-value--red"><template v-if="order.price.discount > 0">-</template>{{ order.price.discount }} лв.</div>
                                </div>
                                <div class="order__field">
                                    <div class="order__field-name">Обща стойност</div>
                                    <div class="order__field-value order__field-value--active">{{ order.price.special }} лв.</div>
                                </div>
                                <div class="order__field order__field--border">
                                    <div class="order__field-name">Статус на поръчката</div>
                                    <div class="order__field-value" :class="{'order__field-value--red': order.status.id === 'CANCELLED'}">
                                        {{ order.status.text }}
                                    </div>
                                </div>
                                <div class="order__field">
                                    <div class="order__field-name">Статус на плащане</div>
                                    <div class="order__field-value">
                                        <template v-if="order.pay_status === 'COMPLETED'">Платена</template>
                                        <template v-else-if="order.pay_status === 'RESERVED'">Сумата е блокирана</template>
                                        <template v-else>Не платена</template>
                                    </div>
                                </div>

                                <div v-if="order.comment != null && order.comment.length > 0" class="cloud">
                                    <div class="cloud__title">Коментар към поръчката&nbsp;&nbsp;💬</div>
                                    <div class="cloud__desc">{{ order.comment }}</div>
                                </div>

                                <div class="cloud">
                                    <div class="cloud__title">Метод на плащане</div>
                                    <div class="payment" v-if="order.payment !== null">
                                        <div class="payment__image"><img :src="order.payment.icon"/></div>
                                        <div class="payment__title">{{ order.payment.title }}</div>
                                    </div>
                                </div>

                                <input
                                        v-b-modal.modalOrderCancel
                                        v-if="['CREATED', 'ASSEMBLING', 'ASSEMBLED'].includes(order.status.id)"
                                        class="order__cancel btn btn-tilda"
                                        type="button"
                                        value="Отмяна на поръчката"/>

                                <div v-if="this.$store.state.error.showErrorMessage" class="alert alert-danger" role="alert">
                                    {{ this.$store.state.error.errorMessage }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
                ref="modalOrderCancel"
                id="modalOrderCancel"
                modal-class="order-modal"
                title="Отмяна на поръчката?"
                hide-footer
                centered>
            <br>
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">
                    <b-button variant="primary" class="btn-tilda" block @click="cancelOrder()">Да</b-button>
                </div>
                <div class="col-sm-3">
                    <b-button variant="light" block @click="$bvModal.hide('modalOrderCancel')">Не</b-button>
                </div>
                <div class="col-sm-3"></div>
            </div>
        </b-modal>

        <b-modal
                v-if="order != null"
                ref="modalOrderCongratulations"
                id="modalOrderCongratulations"
                modal-class="order-modal"
                title="Поръчката ви е приета"
                hide-footer
                centered>
            <p>Очаквайте доставка <template v-if="!order.slot.text.includes('Днес') && !order.slot.text.includes('Утре')">на </template>{{ order.slot.text.toLowerCase() }}</p>
            <br>
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6">
                    <b-button variant="primary" class="btn-tilda" @click="$bvModal.hide('modalOrderCongratulations')">Добре</b-button>
                </div>
                <div class="col-sm-3"></div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'ProfileOrder',
        components: {

        },
        data: function () {
            return {
                congratulationsShown: false
            }
        },
        computed: {
            user() {
                return this.$store.state.auth.user
            },
            order() {
                return this.$store.state.orders.profileOrder
            }
        },
        methods: {
            ...mapActions([
                'profileOrder',
                'profileOrderCancel',
            ]),
            async getOrder(params) {
                await this.profileOrder(params)
            },
            async cancelOrder() {
                this.$refs['modalOrderCancel'].hide();

                await this.profileOrderCancel({
                    id: this.order.id
                })
            }
        },
        created() {
            this.getOrder({
                id: this.$route.params.orderId
            });
        },
        watch: {
            order: function (newVal) {
                if (this.congratulationsShown === false && this.$route.params.congratulations === true) {
                    this.congratulationsShown = true;
                    setTimeout(function (_this) {
                        _this.$refs['modalOrderCongratulations'].show();
                    }, 500, this);
                }
            }
        }
    }
</script>

<style lang="scss">
    .order-modal {

        .modal-dialog {
            width: 450px;
        }

        .col-sm-6 {
            text-align: center;
        }

        .btn-tilda {

        }
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/css/base.scss";

    .alert {
        margin-top: 25px;
    }

    .page {
        padding-top: 110px;
        min-height: 100vh;
        background: #fafafa;

        &__back {
            margin-bottom: 30px;

            &:hover svg {
                fill: $tilda;
            }

            svg {
                padding: 5px;
                position: relative;
                top: -1px;
            }
        }
    }

    .profile {
        padding-bottom: 50px;

        &__sidebar {
            background: rgb(255, 255, 255);
            box-shadow: rgb(93 62 188 / 4%) 0 6px 24px;
            border-radius: 8px;
            padding: 20px 20px 0;
        }

        &__name {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            padding: 10px 0;
        }

        &__field {
            border-top: 1px solid rgb(243, 240, 254);
            padding: 16px 0;
        }

        &__icon {

        }

        &__value {

        }

        &__title {
            font-weight: 700;
            font-size: 22px;
            margin-bottom: 15px;
            margin-top: 5px;
        }
    }

    .order {

        &__id {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        &__service {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        &__date {
            font-size: 12px;
            opacity: 0.6;
            margin-top: 5px;
        }

        &__address {
            font-size: 12px;
            opacity: 0.6;
            margin-top: 5px;
            margin-bottom: -20px;
        }

        &__content {
            background: #FFFFFF;
            -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 5%);
            -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 20px rgb(0 0 0 / 5%);
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            border-radius: 12px;
            padding: 25px;
            margin: 48px 0 12px;
        }

        &__field {
            margin-top: 10px;

            &--border {
                border-top: 1px solid #f4f4f4;
                padding-top: 10px;
            }

            &-name {
                display: inline-block;

                &--bold {
                    font-weight: 700;
                }
            }

            &-value {
                font-weight: 700;
                display: inline-block;
                float: right;

                &--active {
                    color: $tilda;
                }

                &--red {
                    color: #FF3B30;
                }

                &--discount {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 9px;
                        width: 100%;
                        height: 2px;
                        display: block;
                        background: #FF3B30;
                        opacity: 0.7;
                        -webkit-transform: rotate(-3deg);
                        transform: rotate(-3deg);
                    }
                }
            }
        }

        &__summary {
            font-size: 18px;
            font-weight: 700;

            span {
                color: $tilda;
            }
        }

        &__products {
            margin-top: 20px;
        }

        &__cancel {
            width: 100%;
            height: 50px;
            margin-top: 25px;
        }
    }

    .cloud {
        background: #F9F9F9;
        border-radius: 8px;
        padding: 16px 16px 16px;
        margin-top: 25px;

        &__title {
            font-weight: 700;
            font-size: 15px;
            margin-bottom: 10px;
        }

        &__desc {
            opacity: 0.6;
        }
    }

    .payment {

        &__image {
            width: 50px;
            display: inline-block;

            img {
                max-width: 40px;
                min-width: 35px;
            }
        }

        &__title {
            display: inline-block;
            margin-left: 10px;
        }
    }

    .order-product {
        padding: 2px 0;
        margin: 15px 0;

        &--checkout {
            margin: 0 20px;
            padding: 15px 0;
            border-bottom: 1px solid #eee;
        }

        &--delivery {
            margin-top: 5px;
            padding: 15px 0 0;
            border-top: 1px solid #dedede;
        }

        &__photo {
            float: left;
            width: 60px;
            height: 60px;
            margin-right: 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 1px solid #F2F2F2;
                border-radius: 12px;
            }
        }

        &__title {
            font-size: 14px;
            line-height: 20px;
            padding-right: 15px;
        }

        &__weight {
            color: #b0b0b0;
            font-size: 10px;
            white-space: nowrap;
        }

        &__content {
            float: left;
            width: calc(100% - 70px);
        }

        &__info {
            float: right;
            width: 70px;
            text-align: right;
        }

        &__price {
            margin-bottom: 2px;

            &--old {
                position: relative;
                float: right;

                &:before {
                    content: "";
                    position: absolute;
                    top: 9px;
                    width: 100%;
                    height: 2px;
                    display: block;
                    background: #FF3B30;
                    opacity: 0.7;
                    -webkit-transform: rotate(-3deg);
                    transform: rotate(-3deg);
                }
            }
        }

        &__quantity {
            opacity: 0.5;
        }
    }

    .empty {
        background: #FFFFFF;
        -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 5%);
        -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 20px rgb(0 0 0 / 5%);
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        margin: 0 0 12px;
        text-align: center;
        padding: 50px;

        &__icon {
            margin-bottom: 30px;
            font-size: 50px;
            margin-top: 20px;
        }

        &__desc {
            font-size: 20px;
            opacity: 0.6;
            margin-top: 40px;
        }
    }

</style>